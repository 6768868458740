import React, { useEffect, useMemo } from "react";
import NoDataFound from "../MiroComponents/NoDataFound/NoDataFound";
import { useLazyQuery } from "@apollo/client";
import { GETTESTPRECALCULATIONPROCESSING } from "../../Graphql/Queries";
import MyLoader from "../MiroComponents/PreLoader";
import { SmartInput } from "../MiroComponents/SmartInput/SmartInput";
import { debounce } from "lodash";

export const PreCalculatedValues = ({
  precalculations,
  mdeData,
  setMdeData,
}) => {
  const [gettestdataautoprocessing] = useLazyQuery(
    GETTESTPRECALCULATIONPROCESSING
  );
  useEffect(() => {
    if (mdeData?.data?.rows.length === 0) return;
    const { confidencelevel, statisticalpower, weeks } = mdeData?.data;
    const { users, transactions } = mdeData?.data?.rows[0];
    (async function () {
      const { data, loading, error } = await gettestdataautoprocessing({
        variables: {
          users: users,
          transactions: transactions,
          weeks: weeks,
          numberofvariants: 2,
          confidencelevel: confidencelevel,
          statisticalpower: statisticalpower,
        },
      });
      setMdeData((oldData) => ({
        ...oldData,
        loading,
        data: data?.getTestPreCalculationsAutoDataProcessing,
      }));
    })();
  }, []);

  if (precalculations?.loading || mdeData?.loading) {
    return <MyLoader />;
  }
  if (!precalculations?.getPrecalculatedData)
    return (
      <NoDataFound
        title="No Precalculations found"
        description="
    No Precalculations data found for this creteria, try to change filters and date range.
  "
      />
    );
  return (
    <div>
      <div className="seq-values">
        <p>
          Number of Weeks :{" "}
          <span>{precalculations?.getPrecalculatedData?.weeks}</span>
        </p>
      </div>

      {precalculations?.getPrecalculatedData?.rows?.map((row, index) => (
        <React.Fragment key={index}>
          <div className="impressions" id="total">
            <div className="impression-data">
              <div className="impression-heading">
                <h4>Users</h4>
              </div>
              <span className="data-count">{row?.users}</span>
            </div>

            <div className="impression-data">
              <div className="impression-heading">
                <h4>Transactions</h4>
              </div>
              <span className="data-count">{row?.transactions}</span>
            </div>

            <div className="impression-data">
              <div className="impression-heading">
                <h4>CR</h4>
              </div>
              <span className="data-count">{row?.cr?.toFixed(2)}%</span>
            </div>

            <div className="impression-data">
              <div className="impression-heading">
                <h4>Purchasers</h4>
              </div>
              <span className="data-count">{row?.purchasers}</span>
            </div>
            <div className="impression-data">
              <div className="impression-heading">
                <h4>Revenue</h4>
              </div>
              <span className="data-count">{row?.revenue?.toFixed(2)}</span>
            </div>
          </div>
          <div
            className="impressions"
            id="total"
            style={{
              marginTop: "10px",
            }}
          >
            <div className="impression-data">
              <div className="impression-heading">
                <h4>Average AOV</h4>
              </div>
              <span className="data-count">{row?.avg_aov?.toFixed(2)}</span>
            </div>

            <div className="impression-data">
              <div className="impression-heading">
                <h4>AVG RPU</h4>
              </div>
              <span className="data-count">{row?.avg_rpu?.toFixed(2)}</span>
            </div>

            <div className="impression-data">
              <div className="impression-heading">
                <h4>Std. Dev. AOV</h4>
              </div>
              <span className="data-count">{row?.stddev_aov?.toFixed(2)}</span>
            </div>

            <div className="impression-data">
              <div className="impression-heading">
                <h4>Std. Dev. RPU</h4>
              </div>
              <span className="data-count">{row?.stddev_rpu?.toFixed(2)}</span>
            </div>
          </div>
        </React.Fragment>
      ))}
      <div className="default-margin-top">
        <div className="default-padding default-border">
          <h2>Test Page data</h2>
          <div className="flex justify-content-between default-column-gap">
            <div className="full-width">
              <SmartInput
                label="Weekly traffic (sessions or users)"
                defaultValue={mdeData?.data?.weeklyusers}
                // onChange={(e) => {
                //   debounce(() => {
                //     setMdeData((oldData) => ({
                //       ...oldData,
                //       data: {
                //         ...oldData.data,
                //         rows: [
                //           {
                //             ...oldData.data.rows[0],
                //             weeklyusers: e.target.value,
                //           },
                //         ],
                //       },
                //     }));
                //   }, 1000);
                // }}
                readOnly
              />
            </div>
            <div className="full-width">
              <SmartInput
                label="Weekly conversions"
                defaultValue={mdeData?.data?.weeklyconversions}
                readOnly
              />
            </div>
            <div className="full-width">
              <SmartInput
                label="Number of variants (including control)"
                defaultValue={mdeData?.data?.numberofvariants}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      <div className="default-margin-top mde-cal-wrapper">
        <div className="default-border">
          <div className="flex justify-content-between mde-cal-inner">
            <div className="default-padding bg-dark radius-2 full-width mde-cal-form">
              <h2>CR, confidence level, and power</h2>
              <div className="full-width">
                <SmartInput
                  label="Baseline conversion rate (control)"
                  defaultValue={`${mdeData?.data?.baselineconversionRate} %`}
                  readOnly
                />
              </div>
              <div className="full-width">
                <SmartInput
                  label="Confidence level"
                  defaultValue={mdeData?.data?.confidencelevel * 100}
                  readOnly
                />
              </div>
              <div className="full-width">
                <SmartInput
                  label="Statistical power"
                  defaultValue={mdeData?.data?.statisticalpower * 100}
                  readOnly
                />
              </div>
            </div>
            <div className=" full-width mde-cal-values default-border-right default-padding-left">
              <h2>Number of weeks running test</h2>
              {mdeData?.data?.mderesult.map((mde, index) => (
                <div className="full-width" key={index}>
                  <SmartInput defaultValue={mde?.week} readOnly />
                </div>
              ))}
            </div>
            <div className="full-width mde-cal-values default-border-right">
              <h2>Minimum detectable effect (MDE)</h2>
              {mdeData?.data?.mderesult.map((mde, index) => (
                <div className="full-width" key={index}>
                  <SmartInput defaultValue={`${mde?.mde} %`} readOnly />
                </div>
              ))}
            </div>

            <div className="full-width mde-cal-values">
              <h2>
                Visitors per <br /> variant{" "}
              </h2>
              {mdeData?.data?.mderesult.map((mde, index) => (
                <div className="full-width" key={index}>
                  <SmartInput
                    defaultValue={`${mde?.sampleSizePerVariant?.toLocaleString()}`}
                    readOnly
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <pre
        style={{
          color: "#fff",
        }}
      >
        <code>{precalculations?.getPrecalculatedData?.query}</code>
      </pre>
    </div>
  );
};
