import React from "react";
import "./Button.scss";
import "./SwitchButton.scss";

export const Button = ({ icon, text, onClick, children, ...props }) => {
  return (
    <button onClick={onClick} {...props}>
      {children ? (
        children
      ) : (
        <>
          {icon}
          <span>{text}</span>
        </>
      )}
    </button>
  );
};
