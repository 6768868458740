import { gql } from "@apollo/client";

// CREATE CLIENTS
export const CREATECLIENT = gql`
  mutation Mutation($clientPayload: clientInput) {
    createClient(clientPayload: $clientPayload) {
      _id
      name
    }
  }
`;

export const CREATECLIENTLEARNING = gql`
  mutation Mutation($client: String!, $viewId: String!, $name: String!) {
    createClientLearning(client: $client, viewId: $viewId, name: $name) {
      name
    }
  }
`;

export const CREATECLIENTEXPERIMENTSCRIPT = gql`
  mutation CreateClientExperiment(
    $client: String!
    $viewId: String!
    $experiment: ClientExperimentInput
  ) {
    createClientExperiment(
      client: $client
      viewId: $viewId
      experiment: $experiment
    ) {
      _id
      experimentUrl
      viewId
      status
      name
    }
  }
`;

export const UPDATECLIENTEXPERIMENT = gql`
  mutation UpdateClientExperiment(
    $id: ID!
    $experiment: ClientExperimentInput
  ) {
    updateClientExperiment(_id: $id, experiment: $experiment) {
      variants {
        javascript
        css
        name
        slug
        traffic_allocation
      }
      _id
      viewId
      name
      status
      experimentUrl
      pageTargeting {
        routes
        condition
      }
      audienceTargeting {
        javascript
      }
      createdAt
    }
  }
`;

export const UPDATECLIENTEXPERIMENTSTATUS = gql`
  mutation UpdateClientExperiment(
    $id: ID!
    $experiment: ClientExperimentInput
  ) {
    updateClientExperiment(_id: $id, experiment: $experiment) {
      _id
      name
      status
      experimentUrl
    }
  }
`;

export const UPDATECLIENTEXPERIMENTCODE = gql`
  mutation UpdateClientExperiment(
    $id: ID!
    $experiment: ClientExperimentInput
  ) {
    updateClientExperiment(_id: $id, experiment: $experiment) {
      _id
      javascript
      css
    }
  }
`;

export const CREATECLIENTSCRIPT = gql`
  mutation Mutation(
    $client: String!
    $viewId: String!
    $clientScriptURL: String!
  ) {
    createClientScript(
      client: $client
      viewId: $viewId
      clientScriptURL: $clientScriptURL
    ) {
      viewId
      clientScriptURL
    }
  }
`;

export const UPDATECLIENTLEARNING = gql`
  mutation Mutation($id: ID!, $name: String!) {
    updateClientLearning(_id: $id, name: $name) {
      _id
      name
    }
  }
`;

export const UPDATECLIENTNEXTSTEPS = gql`
  mutation UpdateClientNextStep($id: ID!, $name: String!) {
    updateClientNextStep(_id: $id, name: $name) {
      name
      _id
    }
  }
`;

export const CREATECLIENTSTRATEGY = gql`
  mutation CreateClientStrategy(
    $client: String!
    $viewId: String!
    $year: JSON!
    $month: JSON!
    $strategies: JSON
  ) {
    createClientStrategy(
      client: $client
      viewId: $viewId
      year: $year
      month: $month
      strategies: $strategies
    ) {
      _id
      year
      month
      strategies
    }
  }
`;

export const UPDATECLIENTSTRATEGY = gql`
  mutation UpdateClientStrategy(
    $id: ID!
    $client: String!
    $viewId: String!
    $strategies: JSON!
    $year: JSON!
    $month: JSON!
  ) {
    updateClientStrategy(
      _id: $id
      client: $client
      viewId: $viewId
      strategies: $strategies
      year: $year
      month: $month
    ) {
      _id
      year
      month
      strategies
    }
  }
`;

export const CREATECLIENTNEXTSTEPS = gql`
  mutation CreateClientNextStep(
    $client: String!
    $viewId: String!
    $name: String!
  ) {
    createClientNextStep(client: $client, viewId: $viewId, name: $name) {
      _id
      name
    }
  }
`;

// CREATE USER

// CREATE ANNOUNCEMENT

export const CREATEANNOUNCEMENT = gql`
  mutation Mutation($input: AnnouncementInput!) {
    createAnnouncement(input: $input) {
      _id
    }
  }
`;

// UPDATE ANNOUNCEMENT
export const UPDATEANNOUNCEMENT = gql`
  mutation Mutation($updateAnnouncementId: ID!, $input: AnnouncementInput!) {
    updateAnnouncement(id: $updateAnnouncementId, input: $input) {
      _id
    }
  }
`;

// DELETE ANNOUNCEMENT

export const DELETEANNOUNCEMENT = gql`
  mutation Mutation($deleteAnnouncementId: ID!) {
    deleteAnnouncement(id: $deleteAnnouncementId) {
      _id
    }
  }
`;

// CREATE SNIPPET

export const CREATESNIPPET = gql`
  mutation CreateSnippet($snippetPayload: SnippetInput) {
    createSnippet(snippetPayload: $snippetPayload) {
      _id
    }
  }
`;

export const UPDATESNIPPET = gql`
  mutation Mutation($id: ID!, $snippetPayload: SnippetInput) {
    updateSnippet(_id: $id, snippetPayload: $snippetPayload) {
      _id
    }
  }
`;

// UPDATE REPORT LEARNINGS

export const UPDATEREPORTLEARNINGS = gql`
  mutation Mutation($id: ID!, $learnings: [String!]) {
    updateReportLearnings(_id: $id, learnings: $learnings) {
      _id
    }
  }
`;

export const UPDATEREPORTDETAILS = gql`
  mutation Mutation($id: ID!, $reportDetails: JSON) {
    updateReportDetails(_id: $id, reportDetails: $reportDetails) {
      _id
    }
  }
`;

export const CREATEUSER = gql`
  mutation CreateUser(
    $email: String!
    $password: String!
    $roleid: String
    $client: [String]
    $name: String
  ) {
    createUser(
      email: $email
      password: $password
      roleid: $roleid
      client: $client
      name: $name
    ) {
      _id
    }
  }
`;

// UPDATE USER

export const UPDATEUSER = gql`
  mutation UpdateUser(
    $id: ID!
    $email: String!
    $name: String
    $roleid: [ID]
    $client: [ID]
  ) {
    updateUser(
      _id: $id
      email: $email
      name: $name
      roleid: $roleid
      client: $client
    ) {
      _id
    }
  }
`;

// UPDATE USER ROLES

export const UPDATEUSERROLES = gql`
  mutation UpdateUserRole($roles: [String], $id: String!) {
    updateUserRole(roles: $roles, _id: $id)
  }
`;

// VERIFY TWO FACTOR AUTH

export const VERIFYTWOFACTOR = gql`
  mutation VerifyTwoFactor($authcode: String!) {
    verifyTwoFactor(authcode: $authcode) {
      _id
      token
    }
  }
`;

// LOGIN USER

export const LOGIN = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      _id
      name
      email
      token
      redirectUrl
      role {
        _id
        name
      }
      name
    }
  }
`;

// UPDATE CLIENT
export const UPDATECLIENT = gql`
  mutation UpdateClient($id: String!, $clientPayload: clientInput) {
    updateClient(_id: $id, clientPayload: $clientPayload) {
      _id
    }
  }
`;

// PAGE ELEMENTS
export const CREATEPAGE = gql`
  mutation CreatePageElement($payload: PageElementInput!) {
    createPageElement(payload: $payload) {
      name
    }
  }
`;

// UPDATE PAGE ELEMENTS

export const UPDATEPAGE = gql`
  mutation Mutation($payload: PageElementInput!, $id: String!) {
    updatePageElement(payload: $payload, _id: $id) {
      _id
    }
  }
`;

// CREATE TEST TYPE
export const CREATETESTTYPE = gql`
  mutation Mutation($testTypePayload: pageTestTypeInput) {
    createPageTestType(testTypePayload: $testTypePayload) {
      name
    }
  }
`;

// UPDATE TEST TYPE
export const UPDATETESTTYPE = gql`
  mutation UpdatePageTestType(
    $id: String!
    $testTypePayload: pageTestTypeInput
  ) {
    updatePageTestType(_id: $id, testTypePayload: $testTypePayload) {
      _id
    }
  }
`;
// CREATE GOAL
export const CREATEGOAL = gql`
  mutation Mutation($goalPayload: goalInput) {
    createGoal(goalPayload: $goalPayload) {
      _id
    }
  }
`;

// UPDATE GOAL
export const UPDATEGOAL = gql`
  mutation UpdateGoal($id: String!, $goalPayload: goalInput) {
    updateGoal(_id: $id, goalPayload: $goalPayload) {
      _id
    }
  }
`;

// CREATE TAG
export const CREATETAG = gql`
  mutation Mutation($name: String!) {
    createTag(name: $name) {
      _id
      name
    }
  }
`;

// UPDATE TAG

export const UPDATETAG = gql`
  mutation UpdateTag($name: String!, $id: ID!) {
    updateTag(name: $name, _id: $id) {
      _id
    }
  }
`;

// CREATE HYPOTHESIS
export const CREATEHYPOTHESIS = gql`
  mutation Mutation($hypothesisPayload: HypothesisInput) {
    createHypothesis(hypothesisPayload: $hypothesisPayload) {
      _id
    }
  }
`;

// UPDATE HYPOTHESIS

export const UPDATEHYPOTHESIS = gql`
  mutation UpdateHypothesis(
    $hypothesisPayload: HypothesisInput
    $id: ID
    $sheetId: ID
  ) {
    updateHypothesis(
      hypothesisPayload: $hypothesisPayload
      _id: $id
      sheetId: $sheetId
    ) {
      _id
    }
  }
`;

// CREATE TRIGGER
export const CREATETRIGGER = gql`
  mutation Mutation($triggerPayload: TriggerInput) {
    createtrigger(triggerPayload: $triggerPayload) {
      name
      createdAt
    }
  }
`;

// UPDATE TRIGGERS

export const UPDATETRIGGER = gql`
  mutation Updatetrigger($triggerPayload: TriggerInput, $id: String) {
    updatetrigger(triggerPayload: $triggerPayload, _id: $id) {
      _id
    }
  }
`;

export const CREATETEST = gql`
  mutation Mutation($testPayload: testInput, $sequentialValues: JSON) {
    createtest(testPayload: $testPayload, sequentialValues: $sequentialValues) {
      _id
      testtool
    }
  }
`;

// CREATE CLIENT NOTES

export const CREATECLIENTNOTE = gql`
  mutation Mutation($clientNotePayload: clientNotePayload) {
    createClientNote(clientNotePayload: $clientNotePayload) {
      client {
        _id
      }
      interpretation
      pageType {
        _id
      }
      source
      whatYouSee
      evidence {
        type
        value
      }
    }
  }
`;

// UPDATE CLIENT NOTES

export const UPDATECLIENTNOTE = gql`
  mutation UpdateClientNote(
    $updateClientNoteId: ID!
    $clientNotePayload: clientNotePayload
  ) {
    updateClientNote(
      id: $updateClientNoteId
      clientNotePayload: $clientNotePayload
    ) {
      _id
    }
  }
`;

//CREATE NEW IDEAS

export const CREATENEWIDEAS = gql`
  mutation Mutation($newIdeasPayload: newIdeasPayload) {
    createNewIdeas(newIdeasPayload: $newIdeasPayload) {
      _id
    }
  }
`;

//UPDATE NEW IDEAS

export const UPDATENEWIDEAS = gql`
  mutation UpdateNewIdeas(
    $updateNewIdeasId: ID!
    $newIdeasPayload: newIdeasPayload
  ) {
    updateNewIdeas(id: $updateNewIdeasId, newIdeasPayload: $newIdeasPayload) {
      _id
    }
  }
`;

//create Landingpages

export const CREATELANDINGPAGES = gql`
  mutation CreateLandingPages($landingPagesPayload: LandingPagesPayload) {
    createLandingPages(LandingPagesPayload: $landingPagesPayload) {
      _id
    }
  }
`;

//update Landingpages

export const UPDATELANDINGPAGES = gql`
  mutation UpdateLandingPages(
    $updateLandingPagesId: ID!
    $landingPagesPayload: LandingPagesPayload
  ) {
    updateLandingPages(
      id: $updateLandingPagesId
      LandingPagesPayload: $landingPagesPayload
    ) {
      _id
    }
  }
`;

export const UPDATETEST = gql`
  mutation Updatetest(
    $testPayload: testInput
    $id: String
    $sequentialValues: JSON
  ) {
    updatetest(
      testPayload: $testPayload
      _id: $id
      sequentialValues: $sequentialValues
    ) {
      _id
      name
      createdAt
      status
      traffic_allocation
      urltargeting
      client {
        _id
        name
      }
      variations {
        _id
        name
        csscode
        jscode
        css_code
        baseline
        reset_js
        traffic_allocation
      }
      audiences {
        _id
        name
        rules_js
      }
      environments {
        _id
        name
        rules_js
      }
      goal {
        _id
        api_name
        counting_method
        css_selector
        details
        rules_js
        name
        type
      }
      pages {
        _id
        api_name
        name
        deactivation_mode
        rules_js
        trigger_js
        urltargetings {
          _id
          type
          url
          url_type
        }
      }
    }
  }
`;

export const MAKETESTLIVE = gql`
  mutation Maketestlive($id: String, $livedate: String) {
    maketestlive(_id: $id, livedate: $livedate)
  }
`;
export const MAKETESTENDED = gql`
  mutation CreateReport($id: String) {
    maketestended(_id: $id)
  }
`;
export const CREATEUPDATEMANUALREPORT = gql`
  mutation CreateUpdateManualReport(
    $reportPayload: reportPayload
    $reportId: ID
  ) {
    createUpdateManualReport(reportPayload: $reportPayload, reportID: $reportId)
  }
`;

export const PREVIEWMANUALREPORT = gql`
  mutation GenerateManualReport($reportPayload: reportPayload) {
    generateManualReport(reportPayload: $reportPayload)
  }
`;

export const MUGETGOOGLEANALYTICSDATA = gql`
  mutation GetAnalyticsDataAdmin($options: options) {
    getAnalyticsDataAdmin(options: $options) {
      response
    }
  }
`;

export const GETNEWGOOGLEANALYTICSDATA = gql`
  mutation Mutation($options: options) {
    getAnalyticsData(options: $options) {
      response
    }
  }
`;

export const MUGETGOOGLEANALYTICSDATACLIENT = gql`
  mutation Mutation($options: optionsClient) {
    getAnalyticsDataClient(options: $options) {
      response
    }
  }
`;

// CREATE REPORTS

export const CREATEREPORT = gql`
  mutation CreateReport($reportPayload: reportPayload) {
    createReport(reportPayload: $reportPayload) {
      _id
    }
  }
`;

// CHECK IF REPORT EXISTS

export const CHECKREPORTEXISTS = gql`
  mutation Mutation($test: ID) {
    reportExistsForTest(test: $test)
  }
`;

//  GET GA4 REPORT

export const REPORTGA4 = gql`
  mutation Mutation($options: reportoptions) {
    gaReport(options: $options)
  }
`;

// GET BIG QUERY REPORT

export const REPORTBIGQUERY = gql`
  mutation GetBigQueryData(
    $client: ID!
    $dateRange: DateRanges!
    $codebaseTestId: ID!
    $dataSetId: String!
    $filters: reportFilters
    $file: Upload
  ) {
    getBigQueryData(
      client: $client
      dateRange: $dateRange
      codebaseTestId: $codebaseTestId
      dataSetId: $dataSetId
      filters: $filters
      file: $file
    )
  }
`;

// EDIT SEGMENT
export const UPDATESEGMENTCOMBINATION = gql`
  mutation UpdateSegmentCombination($id: String, $segmentPayload: Segments) {
    updateSegmentCombination(_id: $id, segmentPayload: $segmentPayload) {
      _id
    }
  }
`;

// ADD SEGMENT
export const CREATESEGMENTCOMBINATION = gql`
  mutation CreateSegmentCombination($segmentPayload: Segments) {
    createSegmentCombination(segmentPayload: $segmentPayload) {
      _id
    }
  }
`;

// ADD PAGE ELEMENTS FROM CSV
export const CREATEPAGEELEMENTS = gql`
  mutation CreatePageElements($payload: [PageElementInput!]!) {
    createPageElements(payload: $payload) {
      _id
    }
  }
`;

// CREATE NOTIFICATION

export const CREATENOTIFICATION = gql`
  mutation Mutation($payload: NotificationInput!) {
    createNotification(payload: $payload) {
      _id
    }
  }
`;

// DELETE REPORT AND PUT TEST DRFAT AGAIN

export const DELETEREPORTANDPUSHTESTDRAFT = gql`
  mutation Mutation($testId: ID!) {
    deleteReport(testId: $testId)
  }
`;

// SAVE SEGMENT FOR TEST

export const SAVESEGMENTSFORTEST = gql`
  mutation SaveSegment($test: ID!, $segment: ID!, $filters: JSON) {
    saveSegment(test: $test, segment: $segment, filters: $filters) {
      _id
    }
  }
`;

// NEW CODE FOR SAVING UNSAVING SEGMENTS
export const SAVESEGMENTFILTERS = gql`
  mutation SaveSegmentFilters($test: ID!, $segmentFilters: JSON!) {
    saveSegmentFilters(test: $test, segmentFilters: $segmentFilters) {
      _id
    }
  }
`;

export const UNSAVESEGMENTFILTERS = gql`
  mutation UnsaveSegmentFilters($test: ID!) {
    unsaveSegmentFilters(test: $test) {
      _id
    }
  }
`;

export const UPDATESEGMENTFILTERS = gql`
  mutation Mutation($test: ID!, $segmentFilters: JSON!) {
    updateSegmentFilters(test: $test, segmentFilters: $segmentFilters) {
      _id
    }
  }
`;

//

// UPDATE FAQ

export const UPDATEFAQ = gql`
  mutation UpdateFaq(
    $id: ID!
    $description: String
    $title: String
    $keywords: [String]
  ) {
    updateFaq(
      _id: $id
      description: $description
      title: $title
      keywords: $keywords
    ) {
      _id
      description
    }
  }
`;

// TOGGLE CLIENT PORTFOLIO

export const TOGGLECLIENTPORTFOLIO = gql`
  mutation ToggleClientPortfolio($id: ID!, $portfolioClient: Boolean!) {
    toggleClientPortfolio(_id: $id, portfolioClient: $portfolioClient) {
      _id
    }
  }
`;

export const COMPAREPORTFOLIO = gql`
  mutation Mutation($payload: JSON) {
    getPortfolioComparison(payload: $payload)
  }
`;

// CREATE FAQ

export const CREATEFAQ = gql`
  mutation CreateFaq(
    $title: String
    $description: String
    $keywords: [String]
  ) {
    createFaq(title: $title, description: $description, keywords: $keywords) {
      _id
    }
  }
`;

// UNSAVE SEGMENT FOR TEST
export const UNSAVESEGMENTFORTEST = gql`
  mutation UnsaveSegment($test: ID!, $segment: ID!) {
    unsaveSegment(test: $test, segment: $segment) {
      _id
    }
  }
`;

// ARCHIVE NOTIFICATIONSFORUSER

export const ARCHIVENOTIFICATION = gql`
  mutation Mutation($id: ID!) {
    archiveNotification(_id: $id)
  }
`;

// CREATE FEEDBACK

export const CREATEFEEDBACK = gql`
  mutation Mutation($feedbackPayload: InputFeedback) {
    createFeedback(feedbackPayload: $feedbackPayload) {
      title
    }
  }
`;

// MARK NOTIFICATION READ

export const READNOTIFICATION = gql`
  mutation Mutation($notificationId: ID!, $userId: ID!) {
    readNotification(notificationId: $notificationId, userId: $userId)
  }
`;
// FIND USERS BY CLIENT

export const USERSBYCLIENT = gql`
  mutation FindClientUsers($clientId: ID!) {
    findClientUsers(clientId: $clientId)
  }
`;

// REMOVE CLIENT FROM USER
export const REMOVECLIENTFROMUSER = gql`
  mutation RemoveClientUsers($email: String!, $clientId: ID!) {
    removeClientUsers(email: $email, clientId: $clientId) {
      _id
      email
    }
  }
`;

// DELETE/DEACTIVATE USER

export const DEACTIVATEUSER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(_id: $id)
  }
`;

//REVIVE/ACTIVATE USERS
export const ACTIVATEUSER = gql`
  mutation ReviveUser($id: ID!) {
    reviveUser(_id: $id)
  }
`;

// ADD GOALS FROM CSV
export const CREATEMULTIPLEGOALS = gql`
  mutation CreateGoals($goalsPayload: [goalInput]) {
    createGoals(goalsPayload: $goalsPayload) {
      _id
    }
  }
`;

// ADD TRIGGERS FROM CSV

export const CREATEMULTIPLETRIGGERS = gql`
  mutation Mutation($triggersPayload: [TriggerInput]) {
    createtriggers(triggersPayload: $triggersPayload) {
      _id
    }
  }
`;
// ADD STAGES IN CUSTOMER JOURNEYS CSV

export const CREATESTAGESINCUSTOMERSJOURNEY = gql`
  mutation CreateStageInCustomerJourneys(
    $payload: [StageInCustomerJourneyInput]!
  ) {
    createStageInCustomerJourneys(payload: $payload) {
      _id
    }
  }
`;

// ADD HYPOTHESIS FROM CSV

export const CREATEHYPOTHESISCSV = gql`
  mutation Mutation($hypothesisPayloads: [HypothesisInput]) {
    createHypotheses(hypothesisPayloads: $hypothesisPayloads) {
      _id
    }
  }
`;

// UPDATE RETENTIONS

export const UPDATECLIENTRETENTIONS = gql`
  mutation UpsertClientRetention($input: ClientRetentionInput!) {
    upsertClientRetention(input: $input) {
      _id
      retentions {
        active
        month
        monthName
        retainerValue
        otherPayments
        year
      }
    }
  }
`;

// REFETCH REPORT

export const REFETCHCLIENTREPORT = gql`
  mutation RefetchTemporaryReport($id: ID!, $endDate: String!) {
    refetchTemporaryReport(_id: $id, end_date: $endDate) {
      response
    }
  }
`;

// REFETCH REPORT GA4

export const REFETCHCLIENTREPORTGA4 = gql`
  mutation RefetchTemporaryReportGa4($id: ID!, $dateRanges: [DateRanges]!) {
    refetchTemporaryReportGa4(_id: $id, dateRanges: $dateRanges)
  }
`;

export const REFETCHCLIENTREPORTBIGQUERY = gql`
  mutation RefetchTemporaryReportBigQuery(
    $id: ID!
    $dateRange: DateRanges!
    $otherFilters: JSON
  ) {
    refetchTemporaryReportBigQuery(
      _id: $id
      dateRanges: $dateRange
      otherFilters: $otherFilters
    )
  }
`;

// GET PRE CALCULATED VALUES

export const GETPRECALCULATEDVALUES = gql`
  mutation GetPrecalculatedData(
    $dateRange: DateRanges!
    $dataSetId: String!
    $filters: JSON
    $client: ID
    $codebaseTestId: ID
  ) {
    getPrecalculatedData(
      dateRange: $dateRange
      dataSetId: $dataSetId
      filters: $filters
      client: $client
      codebaseTestId: $codebaseTestId
    )
  }
`;

// DEACTIVATE REPORT

export const DEACTIVATEREPORT = gql`
  mutation DeleteReport($id: ID!) {
    deactiveTemporaryReport(_id: $id)
  }
`;

export const CREATECLIENTLINKS = gql`
  mutation CreateClientLink($clientLinksInput: ClientLinksInput) {
    createClientLink(ClientLinksInput: $clientLinksInput) {
      _id
    }
  }
`;

export const UPDATECLIENTLINKS = gql`
  mutation Mutation($id: ID!, $clientLinksInput: ClientLinksInput) {
    updateClientLink(_id: $id, ClientLinksInput: $clientLinksInput) {
      _id
      description
    }
  }
`;

export const DELETECLIENTLINK = gql`
  mutation DeleteClientLink($id: ID!) {
    deleteClientLink(_id: $id)
  }
`;

// CLIENT ACCESSES

export const SENDWELCOMEEMAIL = gql`
  mutation Mutation($email: String!) {
    sendNDA(email: $email)
  }
`;

export const SENDCREDENTIALS = gql`
  mutation Mutation($id: ID!, $email: String!) {
    sendCredentials(_id: $id, email: $email)
  }
`;

export const UPDATENDASTATUS = gql`
  mutation Mutation($id: ID!, $NDAStatus: Boolean) {
    updateNDAStatus(_id: $id, NDAStatus: $NDAStatus) {
      _id
      NDAStatus
    }
  }
`;

// Reset Password Mutations
export const SENDRESETPASSWORDEMAIL = gql`
  mutation Mutation($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`;

export const RESETPASSWORD = gql`
  mutation Mutation($password: String!, $userId: ID!, $token: String!) {
    resetPassword(password: $password, userId: $userId, token: $token) {
      email
    }
  }
`;

export const CHANGEPASSWORD = gql`
  mutation Mutation(
    $email: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      email: $email
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      _id
      email
      name
    }
  }
`;

// CVR reports Mutations
export const ADDCVRREPORT = gql`
  mutation Mutation($client: ID, $reportLink: String) {
    addCVRReport(client: $client, reportLink: $reportLink) {
      _id
      reportLink
    }
  }
`;

export const UPDATECVRREPORT = gql`
  mutation Mutation($id: ID!, $client: ID, $reportLink: String) {
    updateCVRReport(id: $id, client: $client, reportLink: $reportLink) {
      _id
      reportLink
    }
  }
`;

export const DELETECVRREPORT = gql`
  mutation Mutation($id: ID!) {
    deleteCVRReport(id: $id) {
      _id
      reportLink
    }
  }
`;

//  Mutations
export const CREATECLIENTSURVEY = gql`
  mutation Mutation($user: ID, $responses: JSON) {
    createClientSurvey(user: $user, responses: $responses) {
      _id
      responses
    }
  }
`;

export const FETCHADDITIONALREVENUEFORCLIENT = gql`
  mutation FetchAdditionalRevenuePerClient($client: ID!) {
    fetchAdditionalRevenuePerClient(client: $client)
  }
`;

// BUSINESS CASE PROJECTION

export const CREATEBUSINESSCASEPROJECTION = gql`
  mutation CreateBusinessCaseProjection(
    $aov: Int!
    $avgneworderpermonth: Int!
    $avgcm2: Int!
    $avg365Dclv: Int!
    $startDate: Date!
    $endDate: Date!
    $instanteffect: Float!
    $secondaryeffect: Float!
    $guaranteedoutcome: Float!
  ) {
    createBusinessCaseProjection(
      AOV: $aov
      AVGNEWORDERPERMONTH: $avgneworderpermonth
      AVGCM2: $avgcm2
      AVG365DCLV: $avg365Dclv
      startDate: $startDate
      endDate: $endDate
      INSTANTEFFECT: $instanteffect
      SECONDARYEFFECT: $secondaryeffect
      GUARANTEEDOUTCOME: $guaranteedoutcome
    ) {
      data
    }
  }
`;

export const UPDATECLIENTSCRIPT = gql`
  mutation UpdateClientScript(
    $client: ID!
    $clientScriptData: clientScriptInput
  ) {
    updateClientScript(client: $client, clientScriptData: $clientScriptData) {
      _id
    }
  }
`;

export const CREATECLIENTBUSINESSCASEPROJECTION = gql`
  mutation GetBusinessCaseValidationData(
    $dateRange: DateRanges!
    $client: ID
    $dataSetId: String
    $avgcm2: Int!
    $guaranteedoutcome: Float!
    $sheetData: String
  ) {
    getBusinessCaseValidationData(
      dateRange: $dateRange
      client: $client
      dataSetId: $dataSetId
      AVGCM2: $avgcm2
      GUARANTEEDOUTCOME: $guaranteedoutcome
      sheetData: $sheetData
    )
  }
`;

export const CREATEAUDIENCE = gql`
  mutation CreateAudience($audiencePayload: audienceInput) {
    createAudience(audiencePayload: $audiencePayload) {
      _id
      name
      rules_js
    }
  }
`;

export const CREATEGOALS = gql`
  mutation CreateGoal($goalPayload: goalInput) {
    createGoal(goalPayload: $goalPayload) {
      _id
      client {
        _id
        name
      }
      api_name
      counting_method
      css_selector
      details
      name
      rules_js
      type
    }
  }
`;

export const CREATEPAGEABTEST = gql`
  mutation CreatePage($pageData: PageInput) {
    createPage(pageData: $pageData) {
      _id
      client {
        _id
        name
      }
      api_name
      deactivation_mode
      name
      poll_on_rules
      rules_js
      trigger
      trigger_js
      urltargetings {
        _id
        type
        url
        url_type
      }
    }
  }
`;

export const UPDATEAUDIENCE = gql`
  mutation UpdateAudience($id: String!, $audiencePayload: audienceInput) {
    updateAudience(_id: $id, audiencePayload: $audiencePayload) {
      _id
      name
      rules_js
    }
  }
`;

export const UPDATEPAGEABTEST = gql`
  mutation UpdatePage($id: ID!, $pageData: PageInput) {
    updatePage(_id: $id, pageData: $pageData) {
      _id
      api_name
      client {
        _id
        name
      }
      deactivation_mode
      name
      poll_on_rules
      rules_js
      trigger
      trigger_js
      urltargetings {
        _id
        type
        url
        url_type
      }
    }
  }
`;

export const UPDATEGOALS = gql`
  mutation updateGoal($id: String!, $goalPayload: goalInput) {
    updateGoal(_id: $id, goalPayload: $goalPayload) {
      _id
      client {
        _id
        name
      }
      api_name
      counting_method
      css_selector
      details
      name
      rules_js
      type
    }
  }
`;

export const CREATEENVIRONMENT = gql`
  mutation CreateEnvironment($environmentPayload: environmentInput) {
    createEnvironment(environmentPayload: $environmentPayload) {
      _id
      name
      rules_js
    }
  }
`;

export const UPDATEENVIRONMENT = gql`
  mutation UpdateEnvironment(
    $id: String!
    $environmentPayload: environmentInput
  ) {
    updateEnvironment(_id: $id, environmentPayload: $environmentPayload) {
      _id
      name
      rules_js
    }
  }
`;

export const ADDUPDATEVARIANT = gql`
  mutation AddUpdateTestVariant($test: ID!, $variant: VariationsInput) {
    addUpdateTestVariant(test: $test, variant: $variant)
  }
`;

export const DELETEVARIANT = gql`
  mutation DeleteTestVariant($test: ID!, $variant: ID!) {
    deleteTestVariant(test: $test, variant: $variant)
  }
`;

export const UPDATEMULTIPLEVARIANTS = gql`
  mutation UpdateMutipleVariants($test: ID!, $variants: [VariationsInput]) {
    updateMutipleVariants(test: $test, variants: $variants)
  }
`;

export const REFETCHALLTESTREPORTS = gql`
  mutation GetAllActiveTests($client: String!, $viewId: String!) {
    getAllActiveTests(client: $client, viewId: $viewId) {
      testId
      testName
      currency
      filters
      livedate
      dateRanges {
        startDate
        endDate
      }
      report
    }
  }
`;

export const TOGGLEEXCLUDEREPORT = gql`
  mutation ToggleReportExclude($id: ID!, $value: Boolean) {
    toggleReportExclude(_id: $id, value: $value) {
      _id
      excludedFromCalculations
    }
  }
`;
