import React, { useEffect, useMemo, useState } from "react";
import "./ClientSettings.scss";
import { CLIENTSCRIPT } from "../../../Graphql/Queries";
import { LoaderLogo } from "../../Shared/LoaderLogo";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATECLIENTSCRIPT } from "../../../Graphql/Mutations";
import { toast } from "react-toastify";
import Input from "../../MiroComponents/Input/Input";
import { Button } from "../../MiroComponents/Button/Button";
import Checkbox from "../../MiroComponents/Checkbox/Checkbox";
import { debounce } from "lodash";
import TargetDefination from "../TargetDefination/TargetDefination";

const intialState = {
  debug: false,
  restrict_debug: false,
  is_spa: false,
  run_only_on_reinit: false,
  use_antiflicker: false,
  jscode: "",
  csscode: "",
  rules: "",
  rules_tracking: "",
};

export default function ClientSettings({ client, environments }) {
  const [clientScriptData, setClientScriptData] = useState(intialState);

  const { data, loading, error, refetch } = useQuery(CLIENTSCRIPT, {
    variables: { client },
  });
  useEffect(() => {
    if (data) {
      setClientScriptData(data.getClientScript);
    }
  }, [data]);

  const setCheckBoxValue = (e) => {
    const checkBoxValue = e.target
      .closest(".checkbox-wrapper")
      .querySelector("input").checked;
    return checkBoxValue;
  };
  const setValues = debounce((e) => {
    setClientScriptData({
      ...clientScriptData,
      [e.target.name]: e.target.value,
    });
  }, 500);

  const [updateclientscript] = useMutation(UPDATECLIENTSCRIPT);

  if (loading) return <LoaderLogo />;
  if (error) return <div>{error.message}</div>;
  const { getClientScript } = data;
  return (
    <section className="">
      {getClientScript?.clientScriptURL && (
        <div className="d-inline-flex full-width align-items-end default-margin-bottom">
          <Input
            label="Script Implementation"
            placeholder="Enter site url"
            name="siteurl"
            type="url"
            value={
              // getClientScript?.clientScriptURL
              `<script src="${getClientScript?.clientScriptURL}"></script>`
            }
            wrapperClass="full-width margin-bottom-auto"
            inputWrapperClass="full-width"
            readOnly
            onClick={(e) => {
              navigator.clipboard.writeText(
                `<script src="${getClientScript?.clientScriptURL}"></script>`
              );
              toast.success("Copied to Clipboard");
            }}
            style={{
              cursor: "copy",
            }}
          />
          {/* <Button
            style={{
              whiteSpace: "nowrap",
            }}
            onClick={(e) => {
              navigator.clipboard.writeText(
                `${getClientScript?.clientScriptURL}`
              );
              toast.success("Copied to Clipboard");
            }}
            text="Copy"
            className="smart-button big active"
          /> */}
        </div>
      )}

      <form
        className="default-margin-bottom default-padding default-border bg-1"
        onSubmit={async (e) => {
          e.preventDefault();
          const clientScriptDataPayload = {
            debug: clientScriptData?.debug || false,
            restrict_debug: clientScriptData?.restrict_debug || false,
            is_spa: clientScriptData?.is_spa || false,
            run_only_on_reinit: clientScriptData?.run_only_on_reinit || false,
            jscode: clientScriptData?.jscode ?? "",
            csscode: clientScriptData?.csscode ?? "",
            rules: clientScriptData?.rules ?? "return true;",
            rules_tracking: clientScriptData?.rules_tracking ?? "return true;",
          };
          await updateclientscript({
            variables: {
              client,
              clientScriptData: clientScriptDataPayload,
            },
          });
          toast.success("Client Script Updated");
          refetch();
        }}
      >
        <div>
          <h2> General Settings</h2>
          <Checkbox
            value={clientScriptData?.debug || false}
            name={"debug"}
            label={`Debug Mode`}
            subLabel={`If checked, Info is provided in the Browser's Console`}
            onChange={(e) => {
              setClientScriptData({
                ...clientScriptData,
                debug: setCheckBoxValue(e),
              });
            }}
          />
          <Checkbox
            value={clientScriptData?.restrict_debug || false}
            label={`Restrict Debug Mode`}
            name={"restrict_debug"}
            onChange={(e) => {
              setClientScriptData({
                ...clientScriptData,
                restrict_debug: setCheckBoxValue(e),
              });
            }}
            subLabel={`If checked, you have to set an additional Parameter to see the Debug Output. ?codebase_show_debug=true`}
          />
          <Checkbox
            value={clientScriptData?.is_spa || false}
            label={`Single Page Application Feature`}
            name={"is_spa"}
            subLabel={`Use Features for dynamic Pages (i.e. SPAs) like React, Angular, Vue, etc.`}
            onChange={(e) => {
              setClientScriptData({
                ...clientScriptData,
                is_spa: setCheckBoxValue(e),
              });
            }}
          />
          <Checkbox
            value={clientScriptData?.run_only_on_reinit || false}
            label={`Only Init Script on API Call`}
            name={"run_only_on_reinit"}
            subLabel={`Only run on codebase.reInit(). When checked, the sequence only starts on the call of codebase.reInit(). Otherwise the sequence also starts on each page load by default.`}
            onChange={(e) => {
              setClientScriptData({
                ...clientScriptData,
                run_only_on_reinit: setCheckBoxValue(e),
              });
            }}
          />
          <Checkbox
            value={clientScriptData?.use_antiflicker || false}
            label={`Anti Flicker Management`}
            name={"use_antiflicker"}
            subLabel={`If enabled, the Page either shows after your Scripts have run or after 2 Seconds as Fallback.`}
            onChange={(e) => {
              setClientScriptData({
                ...clientScriptData,
                use_antiflicker: setCheckBoxValue(e),
              });
            }}
          />
          <Input
            type={"textarea"}
            value={clientScriptData?.jscode || ""}
            inputWrapperClass="full-width medium bg-2"
            label="JS Code (Optional)"
            placeholder={""}
            name="jscode"
            onChange={(e) => {
              setValues(e);
            }}
          />
          <Input
            type={"textarea"}
            value={clientScriptData?.csscode || ""}
            inputWrapperClass="full-width medium bg-2"
            label="CSS Code (Optional)"
            placeholder={""}
            name="csscode"
            onChange={(e) => {
              setValues(e);
            }}
          />
        </div>
        <div>
          <h2>
            {" "}
            Prerequisites: Script will initiate only after these settings
          </h2>

          <Input
            type={"textarea"}
            value={clientScriptData?.rules || "return true;"}
            inputWrapperClass="full-width medium bg-2"
            label="Rules - General Startup (Run Variations)"
            subLabel="If you return true, the project will be processed further and potentially show variations. This Function is executed only once and immediately."
            placeholder={""}
            name="rules"
            onChange={(e) => {
              setValues(e);
            }}
          />
          <Input
            type={"textarea"}
            value={clientScriptData?.rules_tracking || "return true;"}
            inputWrapperClass="full-width medium bg-2"
            label="Rules - Tracking and Browser Storage"
            subLabel="If you return true, the tracking and storing data in the browser is enabled. This Function is executed only once and immediately."
            placeholder={""}
            name="rules_tracking"
            onChange={(e) => {
              setValues(e);
            }}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            text="Update Details"
            className="smart-button big active"
          />
        </div>
      </form>
      {/* <form
        className="default-margin-bottom default-padding default-border bg-1"
        onSubmit={async (e) => {
          e.preventDefault();
          const clientScriptDataPayload = {
            debug: clientScriptData?.debug,
            restrict_debug: clientScriptData?.restrict_debug,
            is_spa: clientScriptData?.is_spa,
            run_only_on_reinit: clientScriptData?.run_only_on_reinit,
            jscode: clientScriptData?.jscode,
            csscode: clientScriptData?.csscode,
            rules: clientScriptData?.rules,
            rules_tracking: clientScriptData?.rules_tracking,
          };
          await updateclientscript({
            variables: {
              client,
              clientScriptData: clientScriptDataPayload,
            },
          });
          toast.success("Client Script Updated");
          refetch();
        }}
      >
        <div>
          <h2> Environment Settings</h2>
          <Input
            type={"text"}
            // value={clientScriptData?.jscode || ""}
            inputWrapperClass="full-width medium bg-2"
            label="Name"
            placeholder={""}
            name="name"
            // onChange={(e) => {
            //   setValues(e);
            // }}
          />
          <Input
            type={"textarea"}
            // value={clientScriptData?.csscode || ""}
            inputWrapperClass="full-width medium bg-2"
            label="JS Rule(s)"
            placeholder={"return true;"}
            name="rules_js"
            // onChange={(e) => {
            //   setValues(e);
            // }}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            text="Add Environment"
            className="smart-button small active"
          />
        </div>
      </form> */}
    </section>
  );
}
