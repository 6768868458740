import Select from "react-select";
import { ABCompareIcon, CrossIcon, FilterIcon } from "../Shared/Icons";
import { DateRangeSelectorComponent } from "../Shared/DateRangeSelectorComponent";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../Store";
import { PrecalculatedConditionContainer } from "./PrecalculatedConditionContainer";
import { Button } from "../MiroComponents/Button/Button";
import { OperatorValues } from "../../Libs/Utilities";

export const FilterPrecalculatedValuesComponent = ({
  showCalendar,
  setShowCalendar,
  selectionRange,
  setSelectionRange,
  callBackWhenClose,
  getPreCalculations,
}) => {
  const [eventOrPage, setEventOrPage] = useState(null);
  const [conditions, setConditions] = useState([]);
  const [dimensionFilterExpression, setDimensionFilterExpression] = useState(
    []
  );
  const [valueFilters, setValueFilters] = useState({
    value: null,
    key: null,
    filterType: "string",
    matchType: null,
  });

  const removeSegment = useCallback((fieldName) => {
    const newArr = [...dimensionFilterExpression];
    const fieldNameIndex = newArr?.findIndex(
      (f) => f?.filter?.fieldName === fieldName
    );
    if (fieldNameIndex > -1) {
      newArr.splice(fieldNameIndex, 1);
    }
    setDimensionFilterExpression(newArr);
  });

  const createDimensionFilters = useCallback(
    (value, key, filterType, matchType) => {
      const filterObject = {
        filter: {
          fieldName: key,
        },
      };
      if (filterType === "string") {
        filterObject.filter.stringFilter = {
          matchType: matchType.toUpperCase(),
          value: value?.value,
        };
      }
      const newArr = [...dimensionFilterExpression];
      const fieldName = newArr?.findIndex((f) => f?.filter?.fieldName === key);
      if (fieldName > -1) {
        newArr[fieldName] = filterObject;
      } else {
        newArr.push(filterObject);
      }
      setDimensionFilterExpression(newArr);
    },
    [dimensionFilterExpression]
  );

  return (
    <>
      <section className="selected-test-content live-reports-content">
        <div className="experiment-data-inner">
          <div className="d-flex justify-content-between align-center">
            <h1 className="">Pre-Calculated Data</h1>
            {/* filter icon for mobile screens as general filters will be removed in mobile view */}
            <div className="reporting-mobile-filters">
              <FilterIcon />
              <span className="reporting-mobile-filters__text">Filter</span>
            </div>
          </div>
        </div>
        <div className="experiment-data-navbar">
          <div className="date-selector">
            <DateRangeSelectorComponent
              showCalendar={showCalendar}
              setShowCalendar={setShowCalendar}
              selectionRange={selectionRange}
              setSelectionRange={setSelectionRange}
              className="dark-calendar"
              classNameWrapper="dark-calendar-wrapper"
              callBackWhenClose={callBackWhenClose}
              showDays={true}
              color="#fff"
            />
          </div>
        </div>
        <div className="default-margin-bottom">
          <PrecalculatedConditionContainer
            eventOrPage={eventOrPage}
            setEventOrPage={setEventOrPage}
            setConditions={setConditions}
            conditions={conditions}
          />
        </div>

        <div className="experiment-segment">
          <div className="segment-data align-center">
            <div className="segment-dropdown-value"></div>
            <div className="segment-btn default-margin-bottom">
              <Button
                className="smart-button full-width big active"
                onClick={(e) => {
                  e.preventDefault();
                  getPreCalculations(conditions);
                }}
              >
                Calculate
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const FilterPrecalculatedValues = React.memo(
  FilterPrecalculatedValuesComponent
);
