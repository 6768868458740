import { useLazyQuery, useMutation } from "@apollo/client";
import { GETCLIENTSNAMEANDIDS } from "../../../Graphql/Queries";
import React, { useCallback, useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { returnClientOptions } from "../../../Libs/Utilities";
import { toast } from "react-toastify";
import Select from "react-select";
import { BigQueryDataSetsComponent } from "../../Shared/BigQueryDataSetsComponent";
import { FilterPrecalculatedValues } from "../../NewComponents/FilterPrecalculatedValues";
import { format, subDays } from "date-fns";
import MyLoader from "../../MiroComponents/PreLoader";
import { GETPRECALCULATEDVALUES } from "../../../Graphql/Mutations";
import { PreCalculatedValues } from "../../Shared/PreCalculatedValues";
import { CrossIcon } from "../../Shared/Icons";
import useStore from "../../../Store";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";
import { SmartInput } from "../../MiroComponents/SmartInput/SmartInput";

// const mdeData = {
//   weeks: 6,
//   rows: [
//     {
//       users: 194046,
//       transactions: 10537,
//     },
//   ],
//   weeklyusers: 32341,
//   weeklyconversions: 1756,
//   numberofvariants: 2,
//   confidencelevel: 0.95,
//   statisticalpower: 0.8,
//   baselineconversion: 0.054296403945456234,
//   sampleSizePerGroup: 113190,
//   baselineconversionRate: "5.4",
//   mderesult: [
//     {
//       week: 1,
//       numberofvariants: 2,
//       sampleSizePerVariant: 16170,
//       sampleSizeTotal: 32340,
//       mde: "11.80",
//     },
//     {
//       week: 2,
//       numberofvariants: 2,
//       sampleSizePerVariant: 32340,
//       sampleSizeTotal: 64680,
//       mde: "8.30",
//     },
//     {
//       week: 3,
//       numberofvariants: 2,
//       sampleSizePerVariant: 48510,
//       sampleSizeTotal: 97020,
//       mde: "6.70",
//     },
//     {
//       week: 4,
//       numberofvariants: 2,
//       sampleSizePerVariant: 64680,
//       sampleSizeTotal: 129360,
//       mde: "5.80",
//     },
//     {
//       week: 5,
//       numberofvariants: 2,
//       sampleSizePerVariant: 80850,
//       sampleSizeTotal: 161700,
//       mde: "5.20",
//     },
//     {
//       week: 6,
//       numberofvariants: 2,
//       sampleSizePerVariant: 97020,
//       sampleSizeTotal: 194040,
//       mde: "4.70",
//     },
//   ],
// };

export const PreCalculations = () => {
  const [mdeData, setMdeData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams({});
  const selectedClient = useStore((state) => state.selectedClient);
  const viewId = searchParams.get("viewId");
  const _params = Object.fromEntries([...searchParams]);
  const [precalculations, setPrecalculations] = useState(null);
  const [getprecalculations] = useMutation(GETPRECALCULATEDVALUES);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: subDays(new Date(), 42),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);

  const callBackWhenClose = () => {
    setShowCalendar(false);
  };

  const getPreCalculations = async (conditions) => {
    try {
      const {
        data: { getPrecalculatedData },
        loading,
      } = await getprecalculations({
        variables: {
          client: selectedClient?.value?._id,
          dateRange: {
            startDate: format(selectionRange[0]?.startDate, "yyyyMMdd"),
            endDate: format(selectionRange[0]?.endDate, "yyyyMMdd"),
          },
          dataSetId: viewId,
          filters: conditions,
        },
      });
      const { users, transactions } = getPrecalculatedData?.rows[0];
      const weeks = Math.ceil(getPrecalculatedData?.days / 7);
      setMdeData({
        data: {
          weeks: weeks,
          rows: [
            {
              users: users,
              transactions: transactions,
            },
          ],
          confidencelevel: 0.95,
          statisticalpower: 0.8,
        },
        loading: true,
      });
      setPrecalculations({
        loading,
        getPrecalculatedData,
      });
    } catch (error) {
      setPrecalculations({
        error,
        loading: false,
      });
      toast.error("Error fetching precalculations");
    }
  };

  if (!selectedClient) return <NoDataFound />;
  return (
    <React.Fragment>
      <section className="live-reports">
        <div className="live-reports-inner">
          <h1 className="">Pre-Test Analysis/Calculations </h1>

          <div className="client-data-wrapper">
            <div className="client-details">
              <BigQueryDataSetsComponent
                projectId={
                  selectedClient ? selectedClient?.value?.bqClientId : null
                }
                client={selectedClient?.value ?? null}
                viewId={viewId}
                _params={_params}
                setSearchParams={setSearchParams}
              />
            </div>
          </div>
        </div>
      </section>

      {selectedClient?.value?.bqClientId && viewId && (
        <FilterPrecalculatedValues
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
          callBackWhenClose={callBackWhenClose}
          getPreCalculations={getPreCalculations}
        />
      )}
      {selectedClient && precalculations && (
        <PreCalculatedValues
          precalculations={precalculations}
          mdeData={mdeData}
          setMdeData={setMdeData}
        />
      )}
    </React.Fragment>
  );
};
